import { Box, Button, Grid } from '@mui/material';
import React from 'react';
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RunnerListingView } from './runnerlisting';
import { FormInputText } from '../../forms/fields/text-input-field';
import { splitName } from '../../utils/name-splitter';

export function RunnerSearchForm() {
    const { register, handleSubmit, control, reset, setValue, watch, formState: { errors } } = useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    // Form Submit Function

    const navigate = useNavigate();
    const onSubmit = async (data: any) => {
        navigate({
            pathname: `/runner-name/1`

        });

        const [firstName, middleName, lastName] = splitName(data.fullName);

        setSearchParams({
            "firstName": firstName, 
            "lastName": lastName,
            "fullName": data.fullName
        });
    }

    let {page} = useParams();
    let pageNum = 1;

    if (!page) {
        pageNum = 1;
    } else {
        pageNum = Number(page);
    }

    var fullName = searchParams.get('fullName') !== null ? searchParams.get('fullName')  : "";
    const queryName = fullName !== null ? splitName(fullName) : "";

    let firstName = queryName[0];
    let lastName = queryName[2];

    if (!firstName) {
        firstName = '';
    }
    if (!lastName) {
        lastName = '';
    }
    if (!fullName) {
        fullName = '';
    }

    //console.log(queryName);

    

    return (
        <>
            
            <div style={{
                "margin": "auto",
                "alignItems": 'center',
                "justifyContent": 'center',
                "display": "flex"
            }}>
                <form onSubmit={handleSubmit(onSubmit)} style={{
                    width: "60%",
                }}>
                
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    {/* <Grid item md={4} xs={12}>
                        <FormInputText defaultValue={firstName} name="firstName" label="First Name" control={control} fullWidth={true} rules={{pattern: /^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/}}/>
                        {errors.firstName && <span>Please enter a valid name</span>}
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <FormInputText defaultValue={lastName} name="lastName" label="Last Name" control={control} fullWidth={true} rules={{pattern: /^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/}}/>
                        {errors.lastName && <span>Please enter a valid name</span>}
                    </Grid> */}
                    <Grid item md={8} xs={12}>
                        <FormInputText defaultValue={fullName} name="fullName" label="Full Name" control={control} fullWidth={true} rules={{minLength: 3, pattern: /^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/}}/>
                        {errors.lastName && <span>Please enter a valid name</span>}
                    </Grid>
                    {/* errors will return when field validation fails  */}
                    
                    <Grid item md={4} xs={12}>
                        <Button type="submit" onClick={handleSubmit(onSubmit)} variant="contained">Submit</Button>
                    </Grid>
                </Grid>
                </form>
            </div>

            {
                fullName != '' && <RunnerListingView field="name" firstName={firstName} lastName={lastName} page={pageNum} />
            }
        </>
      );
}