import { Box, Button, Grid, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { getPendingMerges } from '../utils/axios-query';


export default function MergeRequestTable() {

  const [page, setPage] = useState(0);

    //const classes = useStyles();
    function createData(name1: string, name2: string, status: boolean, reason: string, id: number) {
      return { id, name1, name2, status, reason, key: id };
    }

    const {isLoading, data, isError, isSuccess, error} = useQuery({
      queryKey: ['viewPendingMerges'],
      queryFn: () => getPendingMerges(),
      
  });

  


  if (isLoading) {
    return ( <div className="loadingdata">Loading Pending Merges...</div> )
}

if (isError) {
    console.log(error);
    return (<div className="errordata">Unable to load Merges</div>)
}
  
if (isSuccess)
    {

  const rows =
    data.data.map((data: any) => {
      return createData(`${data.runnerOne.firstName} ${data.runnerOne.lastName}`, `${data.runnerTwo.firstName} ${data.runnerTwo.lastName}`, data.stage, data.reason, data.id);
    });

  const ITEMS_PER_PAGE = 25;
  

  const rowsPerPage = ITEMS_PER_PAGE;
  const numPages = Math.ceil(rows.length / rowsPerPage);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="merge request table">
          <TableHead>
            <TableRow key="heading">
              <TableCell>Name 1</TableCell>
              <TableCell>Name 2</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, rows.length > page * rowsPerPage + rowsPerPage ? page * rowsPerPage + rowsPerPage : rows.length).map((row: any, index: number) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.name1}
                </TableCell>
                <TableCell>{row.name2}</TableCell>
                <TableCell>{row.reason}</TableCell>
                <TableCell>
                  <a href={`/merge/${row.id}`}>View Merge</a> - 
                  <a href={`/merges/complete/${row.id}`}>Mark Complete</a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={rows.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
      />
    </>
  );
  }
  return (<></>);
}