import React from 'react';
import { Nav } from './nav';

export function Header() {
    return <div className="header">
    <div className="inner-wrap">
        <div className="logo">
            <h1><img src='/images/cucb_web_logo_2022_350-new.png' width="350" height="128" alt="Credit Union Cherry Blossom Ten Mile Run Results" /></h1>
        </div>
        <div className="separator-1"></div>
        <div className="subtitle">
            <h3>Ten <span>Mile</span> Run & Five <span>Kilometer</span> Run-Walk</h3>
        </div>
        <Nav />
    </div>
</div>
}