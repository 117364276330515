import React, {FC, useState} from 'react';
import { Grid, Pagination as Pagination, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableRowClassKey } from '@mui/material';
import { findPace, identifyDivision, nestedSort } from '../../utils/event-utils';
import {TisTid} from '../tid-tis';
import { useNavigate } from 'react-router-dom';

interface RunnerSingleInterface {
    performance: any;
    sex: string;
}

export const RunnerSingleEventListing: FC<RunnerSingleInterface> = ({performance, sex}) => {

    const [page, setPage] = useState(0);

    const handlePageChange = (event: any, newPage: number) => {
        setPage(newPage);
    }

    // We need to loop over the events and make a listing to display.
    const navigate = useNavigate();
    // Navigation function to view an individual runner
    function viewPerformance(row: any) {
        navigate({
            pathname: `/performance/${row.row.id}`

        });
    }

    const performanceorder = performance.sort(nestedSort("event", "year", null, "DESC"));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: "white",
        },
        '&:nth-of-type(even)': {
          backgroundColor: "grey",
        },
      }));

    return (<>
        <Grid container spacing={1} justifyContent="center" alignItems="center" component={Paper}>
            <Grid item md={12} lg={10} xl={8}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="Runner table">
                        <TableHead>
                        <TableRow>
                            <TableCell width="20%">Race</TableCell>
                            <TableCell align="right" width="20%">PiD / TiD</TableCell>
                            <TableCell align="right" width="20%">PiS / TiS</TableCell>
                            <TableCell align="right" width="5%">Age</TableCell>
                            <TableCell align="right" width="15%">Time</TableCell>
                            <TableCell align="right" width="10%">Pace</TableCell>
                            
                            <TableCell align="right" width="10%">Division</TableCell>
                            
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {performanceorder
                        .slice(page * 10, page * 10 + 10)
                        .map((row: any) => {

                            let distance: number = row.event.eventDistance;

                            

                            return <TableRow
                            hover
                            onClick = {() => viewPerformance({row})}
                            key={row.id}
                            
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer'}}
                            >
                        
                            <TableCell>{row.event.year} {row.event.eventType} ({Math.round(row.event.eventDistance)} Mi)</TableCell>
                            <TableCell align="right">{row.pid} / {row.tid} {/*<TisTid eventId={row.event.id} division={row.division />*/}</TableCell>
                            <TableCell align="right">{row.pis} / {row.tis}</TableCell>
                            <TableCell align="right">{row.age}</TableCell>
                            <TableCell align="right">{row.netTime}</TableCell>
                            <TableCell align="right">{findPace(row.netTime, distance)}</TableCell>
                            
                            <TableCell align="right">{identifyDivision(row.age, sex, row.division)}</TableCell>
                            
                            </TableRow>
                        })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="center" alignItems="center">
            <Grid item md={12} lg={10} xl={8} alignContent="right">
                {/* <Pagination showFirstButton showLastButton count={Math.floor(Number(data.data.count) / 10)} page={pageNum} onChange={handleChange} color="secondary" /> */}
                <TablePagination
                    rowsPerPage={10}
                    rowsPerPageOptions={[10]}
                    count={performanceorder.length}
                    page={page}
                    onPageChange={handlePageChange}
                />
            </Grid>
        </Grid>
    </>)
}
