import { Grid } from '@mui/material';
import React from 'react';
import { RunnerSearchForm } from '../fragments/runners/runner-search';
import { RunnerAgeEventListing } from '../fragments/age-view/age-events';
import { AgeForm } from '../fragments/age-view/age-form';

export function SearchRunnerAge() {
    return <>
    <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item md={12} lg={10} xl={8} alignContent="right">
            <h1>Search By Age Group</h1>
            <AgeForm />
        </Grid>
    </Grid>


       {/*  <RunnerAgeEventListing /> */}
    </>;
}