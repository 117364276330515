
import { Control, Controller, FieldValues } from "react-hook-form";
import React, {FC} from "react";
import { MenuItem, Select, TextField } from "@mui/material";

interface DropDownOptions {
  label: string;
  value: string;
}


interface FormProps {
    name: string;
    control: Control<FieldValues>;
    label: string;
    style?: React.CSSProperties;
    fullWidth?: boolean,
    rules?: any,
    defaultValue?: string;
    options: DropDownOptions[];
  };

export const FormInputDropDown: FC<FormProps> = ({name, control, label, style, fullWidth = false, rules = {}, defaultValue = "", options = []}) => {
  
  const generateSelectOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };
  
  return (
        <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          
          <Select onChange={onChange} value={value} label={label} fullWidth={fullWidth}>
            {generateSelectOptions()}
          </Select>
        )}
      />
    )
}