import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../utils/axios-query';
import { checkLogin } from '../../utils/checkuser';

export function Footer() {
    const [loggedIn, setLoggedIn] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        // check if the user is logged in here
        // update the `loggedIn` state based on the result
        checkLogin().then((result) => setLoggedIn(result));
      }, []);

      const handleClick = (event: any) => {
        event.preventDefault();
        // Your function logic goes here
        console.log("logout");
        logout().then((result) => navigate("/"));

      };

    return <div className="footer" style={{paddingTop: 50}}>
        <div className="inner-wrap">
            <div className="footer-copyright">
       
            <p>Notice something out of place? Any questions or suggestions: please email <a href="mailto:info@cherryblossom.org">info@cherryblossom.org</a></p>
            <p>Copyright {new Date().getFullYear()} <a href="https://www.cherryblossom.org" target="_blank">Cherry Blossom, Inc.</a></p>

        {loggedIn && (
                    <a href="#" onClick={handleClick}>
                    Log Out
                  </a>
                )}
            </div>
        </div>
    </div>;
}
