import React, { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';
import animationData from '../lottie/111843-winner.json';
import { Box, Card } from '@mui/material';
import { useTrail, animated, useSpring } from '@react-spring/web';
import Fireworks, { FireworksHandlers } from '@fireworks-js/react';


interface Props {
    text: string;
  }

interface Delay {
    delay: number;
    text?: string;
    id?: string;
    fontSize?: number;
}

export function FlyInText({ text = "100 Mile Club", delay = 500, fontSize = 16 }: Delay) {
    const characters = Array.from(text);
  
    const trail = useTrail(characters.length, {
      from: { opacity: 0, transform: 'translateY(100%)' },
      to: { opacity: 1, transform: 'translateY(0)' },
      delay: delay,
    });
  
    return (
      <div className="fly-in-text" style={{height: "auto",lineHeight: "1em", padding: "0", margin: "0"}}>
        {trail.map((props, index) => {
          let merged = {...props, ...{fontSize: fontSize}};

          return (
          <animated.span key={index} className="char" style={merged}>
            {characters[index]}
          </animated.span>
        );}
        )}
      </div>
    );
  }

export function OneHundredMileAwardSmall({ text = "100 Mile Club", delay, id="lottie-id" }: Delay) {
    useEffect(() => {

        /* container: container,
          renderer: 'svg',
          loop: false,
          autoplay: true,
          animationData: animationData, */

        const container = document.getElementById(id)!;
    
    const animation = lottie.loadAnimation({
        container: container,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData,
    });
    setTimeout(() => {
      animation.play();
    }, delay); 
    // Return clean up function here
    return () => animation.destroy();
      }, []);

    return (<>
        <Box sx={{ minWidth: 275, display: 'flex' }}  justifyContent="center" alignItems="center">
            <Box sx={{width: 75}} id={id} ></Box>
            <Box sx={{fontSize: 35, height: "auto"}}>
              <FlyInText text={text} delay={delay + 250} fontSize={16} />
            </Box>
        </Box>
    </>);
}

export function OneHundredMileAwardLarge({ text = "100 Mile Club", delay, id="lottie-id" }: Delay) {
    useEffect(() => {

    const container = document.getElementById(id)!;
    
    const animation = lottie.loadAnimation({
        container: container,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData,
    });


    setTimeout(() => {
      animation.play();
    }, delay); 
    // Return clean up function here
    return () => animation.destroy();
      }, []);

    return (<>
        <Box sx={{ minWidth: 275,  }}  justifyContent="center" alignItems="center">
          <Card sx={{padding: "40px", background: "rgba(242, 185, 205, 0.7)", paddingTop: "0", color: "black", border: "2px solid #DC397D", borderRadius: "40px"}} elevation={5}>
            <Box sx={{width: 350, margin: "auto"}} id={id} />
            <FlyInText text={text} delay={delay + 250} fontSize={72} />
          </Card>
        </Box>
    </>);
}

export function LightboxAward() {

  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
    }, 3000)});


  const [show, setShow] = useState(true);

 /*  const fade = useSpring({
    opacity: show ? 1 : 0,
    config: {
      duration: 700,
    },
    background: "rgba(50, 50, 50, 0.7)",
  }); */

  useEffect(() => {
    setTimeout(() => setShow(false), 3000);
  });

  


  return (
    <>
    <FireworkEffect></FireworkEffect>
    {/* <animated.div style={fade}> */}
      {show && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{pointerEvents: "none", zIndex: 999,}}
          color="White"
        >
          <OneHundredMileAwardLarge text="100 Mile Club!" id="lottieaward" delay={0} />
        </Box>
      )}
      
    {/* </animated.div> */}
    
    </>
  );
  }

  export function FireworkEffect() {
    const ref = useRef<FireworksHandlers>(null)


    const toggle = () => {
      if (!ref.current) return
      if (ref.current.isRunning) {
        ref.current.stop()
      } else {
        ref.current.start()
      }
    }
      useEffect(() => {
        setTimeout(() => {
          if (!ref.current) return
          
          if (ref.current.isRunning) {
            ref.current.waitStop();
          }
        }, 4000);
      });

      return (<Fireworks
        options={
          {
            intensity: 13.83,
          }
        }
        ref={ref}
        style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          position: 'fixed',
          background: "rgba(50, 50, 50, 0)",
          pointerEvents: 'none',
        }}
      />);
  };