import { Box, Button, Grid, TextField } from '@mui/material';
import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { registerNewUser } from '../utils/axios-query';
import { useNavigate } from 'react-router-dom';

export function RegisterAccount() {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const navigate = useNavigate();

  const onSubmit = async (data: FieldValues) => {
    // check if there is an errors object
    if (Object.keys(errors).length === 0) {
      // if there are no errors, submit the form
      /* const saltRounds = 10;
      const hashedPassword = await bcrypt.hash(password, saltRounds);
 */
      //console.log(hashedPassword);

        const response = await registerNewUser(data.email, data.password).then((response) => {
            if (response.status === 201) {
            navigate("/protected-login/");
            } else {
                /* errors.username = "Username already exists"; */
            }
        });



    }
    
  };

  const password = watch('password');
  const confirmPassword = watch('confirmPassword');
    return (<>
    <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item md={12} lg={10} xl={8} alignContent="right">
            <h1>Register a new account</h1>  
        </Grid>
    </Grid>
    <form onSubmit={handleSubmit(onSubmit)}>

      <Box display="flex" alignItems="center" justifyContent="center">
        <label style={{fontWeight: 800}}>Email</label>
        <TextField
        sx={{margin: "5px 15px"}}
        id="email"
        label="Email"
        variant="outlined"
        margin="normal"
        {...register("email", { required: true, pattern: /^\S+@\S+$/i  })}
      />

        {errors.email && errors.email.type === 'required' && <span>This field is required</span>}
        {errors.email && errors.email.type === 'pattern' && <span>Invalid email address</span>}
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center">
        <label style={{fontWeight: 800}}>Password</label>
        <TextField
        sx={{margin: "5px 15px"}}
        id="password"
        label="Password"
        type="password"
        variant="outlined"
        margin="normal"
        {...register("password", { required: true, minLength: 8 })}
      />

        {errors.password && errors.password.type === 'required' && <span>This field is required</span>}
        {errors.password && errors.password.type === 'minLength' && <span>Password must be at least 8 characters long</span>}
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center">
        <label style={{fontWeight: 800}}>Confirm Password</label>
        <TextField
        sx={{margin: "5px 15px"}}
        id="confirmPassword"
        label="Confirm Password"
        type="password"
        variant="outlined"
        margin="normal"
        {...register("confirmPassword", { required: true, minLength: 8, validate: (value) => value === password })}
      />

        {errors.confirmPassword && errors.confirmPassword.type === 'required' && <span>This field is required</span>}
        {errors.confirmPassword && errors.confirmPassword.type === 'validate' && <span>Passwords do not match</span>}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Button type="submit">Register</Button>
      </Box>
    </form>
    </>);
}