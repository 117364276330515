import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
  breakpoints: {
    values: {
     xs: 0,
     sm: 450,
     md: 600,
     lg: 900,
     xl: 1200
   }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#dc397d',
    },
    secondary: {
      main: '#304587',
    },
  },
  typography: {    fontFamily: 'Lato',    body1: {      fontFamily: 'Roboto',    },    body2: {      fontFamily: 'Roboto',    },  },
 }); 

 /* import { ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
     xs: 0,
     sm: 450,
     md: 600,
     lg: 900,
     xl: 1200
   }
  },
  
  palette: {
    mode: 'light',
    primary: {
      main: '#dc397d',
    },
    secondary: {
      main: '#f50057',
    },
  },
}; */



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
