import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Header } from './fragments/template/header';
import { Footer } from './fragments/template/footer';
import { SearchRunnerName } from './views/search-runner-name';
import { Home } from './views/home';
import { QueryClient, QueryClientProvider, QueryState } from '@tanstack/react-query';
import { Runner } from './views/runner-view';
import { SearchRunnerAge } from './views/search-runner-age';
import { PerformanceSingle } from './views/performance-single';
import { AwardsLayout } from './views/awards/awards';
import { TopFinishersFiveK } from './views/awards/awards-topfinishers-5k';
import { TopFStreakersTenM } from './views/awards/awards-topstreakers-10m';
import { TopFStreakersFiveK } from './views/awards/awards-topstreakers-5k';
import { TopFinishersTenM } from './views/awards/awards-topfinishers-10m';

import { RegisterAccount } from './views/register';
import { LoginAccount } from './views/login';
import { Box } from '@mui/material';
import MergeRequestTable from './views/merges';
import { RunnerMergeForm } from './views/single-merge';

const queryClient = new QueryClient();

function App() {
  return <QueryClientProvider client={queryClient} >
      <div className="container">
      <Header />

      <Box className="content" paddingLeft={{xs: 2, lg:10,}} paddingRight={{xs: 2, lg:10,}}>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/runner-name/" element={<SearchRunnerName />}></Route>
            <Route path="/runner-name/:page" element={<SearchRunnerName />}></Route>

            <Route path="/runner/:slug" element={<Runner />}></Route>

            <Route path="/performance-search/" element={<SearchRunnerAge />}></Route>
            <Route path="/performance/:id" element={<PerformanceSingle />}></Route>

            <Route path="/awards/" element={<AwardsLayout />}></Route>

            <Route path="/streakers10m/" element={<TopFStreakersTenM />}></Route>
            <Route path="/streakers10m/:page" element={<TopFStreakersTenM />}></Route>
            
            <Route path="/streakers5k/" element={<TopFStreakersFiveK />}></Route>
            <Route path="/streakers5k/:page" element={<TopFinishersFiveK />}></Route>

            <Route path="/finishers5k/" element={<TopFinishersFiveK />}></Route>
            <Route path="/finishers5k/:page" element={<TopFinishersFiveK />}></Route>

            <Route path="/finishers10m/" element={<TopFinishersTenM />}></Route>
            <Route path="/finishers10m/:page" element={<TopFinishersTenM />}></Route>


            <Route path="/register/" element={<RegisterAccount />}></Route>
            <Route path="/protected-login/" element={<LoginAccount />}></Route>


            <Route path="/merges/pending/" element={<MergeRequestTable />}></Route>
            <Route path="/merge/:runnerOne/:runnerTwo" element={<RunnerMergeForm />}></Route>


          </Routes>
        </Box>
      <Footer />
      
      </div>
    </QueryClientProvider>
}

export default App;
