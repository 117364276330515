
import { Control, Controller, FieldValues, useForm, UseFormSetValue } from "react-hook-form";
import React, {FC, useEffect, useState} from "react";
import { Checkbox, FormControl, FormControlLabel, FormLabel, MenuItem, Select, TextField } from "@mui/material";

interface CheckBoxOptions {
  label: string;
  value: string;
}


interface FormProps {
    name: string;
    control: Control<FieldValues>;
    label: string;
    style?: React.CSSProperties;
    fullWidth?: boolean,
    rules?: any,
    defaultValue?: string[];
    options: CheckBoxOptions[];
    setValue: UseFormSetValue<FieldValues>;
  };

export const FormInputCheckBoxGroup: FC<FormProps> = ({name, control, label, setValue, style, fullWidth = false, rules = {}, defaultValue = [], options = []}) => {
  const [selectedItems, setSelectedItems] = useState<any>(defaultValue);

  // we are handling the selection manually here
  const handleSelect = (value: any) => {
    const isPresent = selectedItems.indexOf(value);
    if (isPresent !== -1) {
      const remaining = selectedItems.filter((item: any) => item !== value);
      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems: any) => [...prevItems, value]);
    }
  };

  // we are setting form value manually here
  useEffect(() => {
    setValue(name, selectedItems); 
  }, [selectedItems]);


  const generateSelectOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };
  
  return (
    <FormControl size={"small"} variant={"outlined"}>
      <FormLabel component="legend">{label}</FormLabel>

      <div>
        {options.map((option: any) => {
          return (
            <FormControlLabel
              control={
                <Controller
                  name={name}
                  render={({}) => {
                    return (
                      <Checkbox
                        
                        checked={selectedItems.includes(option.value)}
                        onChange={() => handleSelect(option.value)}
                      />
                    );
                  }}
                  control={control}
                />
              }
              label={option.label}
              key={option.value}
            />
          );
        })}
      </div>
    </FormControl>
  );
}