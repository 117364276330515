import { Grid, Paper, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { VictoryAxis, VictoryChart, VictoryGroup, VictoryLabel, VictoryLine, VictoryScatter, VictoryTheme, VictoryTooltip, VictoryVoronoiContainer } from 'victory';
import {findDistance, findPace, secondsToTime, timeToSeconds} from '../../utils/event-utils';
import { LightboxAward } from '../award-lottie';

interface RunnerStats {
    eventType: string;
    eventLabel?: string;
    performances: Array<any>;
}

export const DetailedRunnerStats: FC<RunnerStats> = ({eventType, eventLabel, performances}) => {

    const theme = useTheme();

    var graphData: any = {
        time: [], 
        timeLabels: {
            xMin: new Date().getFullYear(),
            xMax: 0,
            yMin: 0,
            yMax: 0,
        },
        pace: [], 
        division: [],
        sex: [],
    };

    // We need to massage the data into an array of objects

    performances.map((row: any) => {
        if (row.event.eventType == eventType) {

            let distance = row.event.eventDistance;

            

            graphData.timeLabels.xMin = Math.min(Number(graphData.timeLabels.xMin), row.event.year);
            graphData.timeLabels.xMax = Math.max(Number(graphData.timeLabels.xMax), row.event.year);

            //console.log(row);

            // This event counts in the stats
            if (row.netTime)
            {
                graphData["time"].push({
                    x: row.event.year,
                    y: timeToSeconds(row.netTime),
                    //label: row.netTime

                });

                /* graphData["timeLabels"].push(

                ); */

                graphData["pace"].push({
                    x: row.event.year,
                    y: findPace(row.netTime, distance, true),
                    //label: findPace(row.netTime, distance),

                });
            }

            if (row.pid > 0)
            {graphData["division"].push({
                x: row.event.year,
                y: Math.trunc(100 - ((row.pid / row.tid) * 100)),
                label: `${row.event.year}: Percentile in Division: ${Math.trunc(100 - ((row.pid / row.tid) * 100))}%`,
            });}

            if (row.pis > 0)
            {graphData["sex"].push({
                x: row.event.year,
                y: Math.trunc(100 - ((row.pis / row.tis) * 100)),
                label: `${row.event.year}: Percentile in Sex: ${Math.trunc(100 - ((row.pis / row.tis) * 100))}%`,
            });}
        } 
    });

    //console.log(Number(graphData.timeLabels.xMin));

    // Add some compression to the time
    if (graphData.time.length < 10 ) {
        graphData.timeLabels.xMin = graphData.timeLabels.xMin - 2;
        graphData.timeLabels.xMax = graphData.timeLabels.xMax + 2;
    }

   //console.log(graphData["time"]);

    if (graphData.time.length > 1) {
        return (
            
            <Paper elevation={12} style={{padding: 20, marginBottom: 20}}>
                
            { eventLabel && <h2>{eventLabel}</h2> }
            <Grid container alignContent="center" alignItems="center">
                <Grid item md={12} lg={6} xl={3}>
                    <h4>Runner Net Finish Time</h4>
                    <VictoryChart 
                        padding={{left:100, bottom: 40, right: 20, top: 20}} 
                        theme={VictoryTheme.material} 
                        containerComponent={<VictoryVoronoiContainer />}
                        domain={{x: [graphData.timeLabels.xMin -1, graphData.timeLabels.xMax +1], y: [1800, 9600]}}
                        animate={{
                            duration: 2000,
                            onLoad: { duration: 1000 }
                            
                          }}
                        >
                        <VictoryAxis
                            dependentAxis={true}
                            style={{
                            grid: { stroke: "grey" }
                            }}
                            tickFormat={(y: number) => `${secondsToTime(y)}`}
                        />
                        <VictoryAxis 
                            tickFormat={(x) => `${x}`}
                        />
                        
                        
                        <VictoryLine 
                            data={graphData.time} 
                            labels={({ datum }) => `${datum.x}: ${secondsToTime(datum.y)}`} 
                            labelComponent={<VictoryTooltip/>} 
                            style={{
                                data: { stroke: theme.palette.primary.main, strokeWidth: 4 },
                                parent: { border: "1px solid #ccc"}
                              }}
                        />
                        
                    </VictoryChart>
                </Grid>
                <Grid item md={12} lg={6} xl={3}>
                    <h4>Runner Pace (Minutes / Mile)</h4>
                    <VictoryChart 
                        padding={{left:100, bottom: 40, right: 20, top: 20}} 
                        theme={VictoryTheme.material} 
                        containerComponent={<VictoryVoronoiContainer />}
                        domain={{x: [graphData.timeLabels.xMin -1, graphData.timeLabels.xMax +1], y: [0, 950]}}
                        animate={{
                            duration: 2000,
                            onLoad: { duration: 1000 }
                            
                          }}
                        >
                    <VictoryAxis
                        dependentAxis={true}
                        style={{
                        grid: { stroke: "grey" }
                        }}
                        tickFormat={(y: number) => `${secondsToTime(y)}`}
                    />
                    <VictoryAxis 
                        tickFormat={(x) => `${x}`}
                    />
                        
                        
                        <VictoryLine 
                            data={graphData.pace} 
                            labels={({ datum }) => `${datum.x}: ${secondsToTime(datum.y)}`} 
                            labelComponent={<VictoryTooltip/>} 
                            style={{
                                data: { stroke: theme.palette.primary.main, strokeWidth: 4 },
                                parent: { border: "1px solid #ccc"}
                              }}
                            
                        />
                        
                    </VictoryChart>
                </Grid>
                <Grid item md={12} lg={6} xl={3}>
                    <h4>Place in <span style={{color: theme.palette.secondary.main}} >Sex</span> and <span style={{color: theme.palette.primary.main}} >Division</span></h4>
                    <VictoryChart 
                        theme={VictoryTheme.material}
                        domain={{x: [graphData.timeLabels.xMin -1, graphData.timeLabels.xMax +1], y: [0, 100]}}
                        padding={{left:100, bottom: 40, right: 20, top: 20}} 
                        containerComponent={<VictoryVoronoiContainer 
                            
                            labels={({ datum }) => `${datum.label}`}
                            labelComponent={<VictoryTooltip constrainToVisibleArea/>} 
                            />
                            
                        }
                        
                        animate={{
                            duration: 2000,
                            onLoad: { duration: 1000 }
                            
                          }}
                        
                    >
                        <VictoryAxis
                            dependentAxis={true}
                            style={{
                            grid: { stroke: "grey" }
                            }}
                            tickFormat={(y: number) => `${y}%`}
                        />
                        <VictoryAxis 
                            tickFormat={(x) => `${x}`}
                        />
                        <VictoryGroup 
                            data={graphData.division}
                            labelComponent={<VictoryLabel text=""/>}
                            color={theme.palette.primary.main} 
                            animate={{
                                duration: 2000,
                                onEnter: { duration: 1000 }
                                
                              }}
                              
                        >
                            <VictoryLine 
                              style={{
                                data: { strokeWidth: 4 },
                                parent: { border: "1px solid #ccc"}
                              }}  
                            />
                            {/* <VictoryScatter
                                    size={({ active }) => active ? 8 : 3}
                            /> */}
                        </VictoryGroup>
                        <VictoryGroup 
                            data={graphData.sex}
                            labelComponent={<VictoryLabel text=""/>} 
                            color={theme.palette.secondary.main}
                        >
                            <VictoryLine
                                style={{
                                    data: { strokeWidth: 4 },
                                    parent: { border: "1px solid #ccc"}
                                  }}
                            />
                            {/* <VictoryScatter
                                size={({ active }) => active ? 8 : 3}
                            /> */}
                        </VictoryGroup>
                    </VictoryChart>
                </Grid>

            </Grid>
            </Paper>
        )
    } else {

        const row: any = performances.filter((performance) => performance.event.eventType == eventType)[0];

        // we only have one entry.
        return (
            <Paper elevation={12} style={{padding: 20, marginBottom: 20}}>
            { eventLabel && <h2>{eventLabel}</h2> }
            <Grid container alignContent="center" alignItems="center">
                <Grid item md={12} lg={6} xl={3}>
                    <h4>{row.event.year} Net Time</h4>
                    {row.netTime }
                </Grid>
                <Grid item md={12} lg={6} xl={3}>
                    <h4>{row.event.year} Pace</h4>
                    {findPace(row.netTime, findDistance(row.event.eventType))}
                </Grid>
                <Grid item md={12} lg={6} xl={3}>
                    <h4>{row.event.year} Percentiles in Sex and Division</h4>
                    { row.pis >= row.tis &&
                        `Sex: ${Math.trunc(100 - ((row.pis / row.tis) * 100))}% <br />`
                    }

                    { row.pid >= row.tid &&
                        `Division: ${Math.trunc(100 - ((row.pid / row.tid) * 100))}%`
                    }
                    
                    
                    
                </Grid>
            </Grid>
            </Paper>
        )
    }

}