import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';




export const AwardsLayout =  () => {

    const navigate = useNavigate();

    function handleClick(location: string) {
        navigate({
            pathname: `/${location}/}`,
    
        });
    }

    return (<Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={6}>
            <Card  sx={{margin:"15px"}}>
                <CardActionArea sx={{ minWidth: 275, display: 'flex' }} component={RouterLink} to={`/streakers10m/`}>
                <CardMedia 
                        image="/images/logo-10m-white.png" 
                        sx={{height: 150, width: 150}} 
                        component="img"    
                    />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent>

                        <Typography variant="h5" component="div">
                            Top Streakers (10 Mile)
                        </Typography>

                        <Typography variant="body2">
                            Total consecutive runs completed in the Cherry Blossom Ten Mile Run
                        </Typography>
                    </CardContent>
                </Box>
                </CardActionArea>
            </Card>
            <Card sx={{margin:"15px"}}>
                <CardActionArea sx={{ minWidth: 275, display: 'flex' }} component={RouterLink} to={`/streakers5k/`}>
                <CardMedia 
                        image="/images/logo-5kwhite.png" 
                        sx={{height: 150, width: 150}} 
                        component="img"    
                    />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent>

                    <Typography variant="h5" component="div">
                        Top Streakers (5K)
                    </Typography>

                    <Typography variant="body2">
                        Total consecutive runs completed in the Cherry Blossom 5K Run-Walk
                    </Typography>
                </CardContent>
                </Box>
                </CardActionArea>
            </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
            <Card sx={{margin:"15px"}}>
                <CardActionArea sx={{ minWidth: 275, display: 'flex' }} component={RouterLink} to={`/finishers10m/`}>
                    <CardMedia 
                        image="/images/logo-10m-white.png" 
                        sx={{height: 150, width: 150}} 
                        component="img"    
                    />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent>

                        <Typography variant="h5" component="div">
                            Top Finishers (10 Mile)
                        </Typography>

                        <Typography variant="body2">
                            Total life time miles completed in the Cherry Blossom Ten Mile Run
                        </Typography>
                    </CardContent>
                </Box>
                </CardActionArea>
            </Card>
            <Card sx={{margin:"15px"}}>
                <CardActionArea sx={{ minWidth: 275, display: 'flex' }}  component={RouterLink} to={`/finishers5k/`}>
                <CardMedia 
                        image="/images/logo-5kwhite.png" 
                        sx={{height: 150, width: 150}} 
                        component="img"    
                    />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent>

                        <Typography variant="h5" component="div">
                            Top Finishers (5K)
                        </Typography>

                        <Typography variant="body2">
                            Total life time miles completed in the Cherry Blossom 5K Run-Walk
                        </Typography>
                    </CardContent>
                </Box>
                </CardActionArea>
            </Card>
        </Grid>
    </Grid>)
}