import axios, { AxiosResponse } from "axios";
import qs from 'qs';

export interface SearchOperators {
    field: string;
    firstName?: string;
    lastName?: string;
    age?: number;
    division?: string;
    eventType?: string;
    slug?: string;
    page: number;
    orderBy?: string;
    link?: string;
}

axios.defaults.withCredentials = false;

export async function updateRunnerList(firstName: string = "", lastName: string = "", age: number = 0, division: string = "", eventType: string = "", page: number = 1, slug: string = '', orderBy = '') {


    const query = {
        firstName: {
            contains: encodeURIComponent(firstName),
        },
        lastName: {
            contains: encodeURIComponent(lastName),
        },
        hidden: {
            equals: false,
        }
        /* "performance.age": age && {
            equals: encodeURIComponent(age),
        },
        
        "performance.division": division && {
            contains: encodeURIComponent(division),
        },
        "performance.event.eventType": eventType && {
            equals: encodeURIComponent(eventType),
        },
        akaSlug: slug && {
            equals: encodeURIComponent(slug),
        }, */

    }

    const stringifiedQuery = qs.stringify({
        where: query, // ensure that `qs` adds the `where` property, too!
        depth: 3,
        page: page,
        sort: orderBy,
      }, { addQueryPrefix: true });
    //const requestURL = `${process.env.REACT_APP_API_HOST}api/runners/search/${page}?firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}&age=${age}&division=${encodeURIComponent(division)}&eventType=${encodeURIComponent(eventType)}&slug=${slug}&orderBy=${orderBy}`;
    const requestURL = `${process.env.REACT_APP_API_HOST}api/runners/${stringifiedQuery}`;
    // console.log(requestURL);
    // Send a request to the backend
    return axios.get(requestURL);
    
}


export async function viewRunnerBySlug(slug: string = '') {

    const query = {
        akaSlug: {
            equals: encodeURIComponent(slug),
        },
        
        hidden: {
            equals: false,
        }

    }

    //const requestURL = `${process.env.REACT_APP_API_HOST}api/runners/view/${slug}`;

    const stringifiedQuery = qs.stringify({
        where: query, // ensure that `qs` adds the `where` property, too!
        depth: 3,
      }, { addQueryPrefix: true });
    //const requestURL = `${process.env.REACT_APP_API_HOST}api/runners/search/${page}?firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}&age=${age}&division=${encodeURIComponent(division)}&eventType=${encodeURIComponent(eventType)}&slug=${slug}&orderBy=${orderBy}`;
    const requestURL = `${process.env.REACT_APP_API_HOST}api/runners/${stringifiedQuery}`;
    // console.log(requestURL);
    // Send a request to the backend
    return axios.get(requestURL);
    
}

export async function findRunnerTisTid(eventId: number = 1, sex: string = "", division: string = "") {

    var requestURL:string;
    if (sex) {
        requestURL = `${process.env.REACT_APP_API_HOST}api/performances/tis/${eventId}/${sex}`;
    } else {
        requestURL = `${process.env.REACT_APP_API_HOST}api/performances/tid/${eventId}/${division}`;
    }

    
    // console.log(requestURL);
    // Send a request to the backend
    return axios.get(requestURL);
    
} 



export async function viewPerformancesBySearch(eventType: string[], year: number, division: string, page: number) {

    if (!page) {
        page = 0;
    }

    const query = {
        and: [
            {
                'event.eventType': {
                    in: encodeURIComponent(eventType.join()),
                },
            },
            {
                'event.year': {
                    equals: encodeURIComponent(year),
                },
            }
        ],
        division: {
            contains: encodeURIComponent(division),
        },
        "runner.hidden": {
            equals: false,
        }


    }

    const stringifiedQuery = qs.stringify({
        where: query, // ensure that `qs` adds the `where` property, too!
        depth: 3,
        page: page,
      }, { addQueryPrefix: true });
    
    const requestURL = `${process.env.REACT_APP_API_HOST}api/performances/${stringifiedQuery}`;

    //const requestURL = `${process.env.REACT_APP_API_HOST}api/performances/search/${eventType.join()}/${year}/${division}/${page}`;
    // console.log(requestURL);
    // Send a request to the backend
    return axios.get(requestURL);
    
}

export async function viewPerformanceById(id: string | undefined) {

    if (id == undefined) 
        id = "0";
    const query = {
        
        id: {
            equals: encodeURIComponent(id),
        },
        "runner.hidden": {
            equals: false,
        }


    }

    const stringifiedQuery = qs.stringify({
        where: query, // ensure that `qs` adds the `where` property, too!
        depth: 3,
      }, { addQueryPrefix: true });
    
    const requestURL = `${process.env.REACT_APP_API_HOST}api/performances/${stringifiedQuery}`;
    // console.log(requestURL);
    // Send a request to the backend
    return axios.get(requestURL);
    
}

export async function registerNewUser(email: string, pass: string) {

    const data = {
        password: pass,
        passwordConfirm: pass,
        email: email,
      };

    const requestURL = `${process.env.REACT_APP_API_HOST}api/auth/admin/register/`;
    // console.log(requestURL);
    // Send a request to the backend
    return axios.post(requestURL, data)
    
}

export async function loginUser(email: string, pass: string) {

    const data = {
        password: pass,
        email: email,
      };

    const requestURL = `${process.env.REACT_APP_API_HOST}api/auth/admin/login/`;
    // console.log(requestURL);
    // Send a request to the backend
    return axios.post(requestURL, data)
    
}


export async function getUser() {

    const requestURL = `${process.env.REACT_APP_API_HOST}api/auth/admin/user/`;
    // console.log(requestURL);
    // Send a request to the backend
    return axios.get(requestURL);
    
}

export async function logout() {

    const requestURL = `${process.env.REACT_APP_API_HOST}api/auth/admin/logout/`;
    // console.log(requestURL);
    // Send a request to the backend
    return axios.get(requestURL);
    
}


export async function getPendingMerges() {

    const requestURL = `${process.env.REACT_APP_API_HOST}api/merges/pending/`;
    // console.log(requestURL);
    // Send a request to the backend
    return axios.get(requestURL);
    
}


export const fetchRunnerById = async (runnerId: string): Promise<AxiosResponse> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/runners/${runnerId}`);
      return response;
    } catch (error) {
      throw error;
    }
  };