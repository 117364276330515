export const nestedSort = (prop1: any, prop2: any = null, prop3: any = null, direction: string = 'asc') => (e1: any, e2: any) => {
    const a = prop3 ? e1[prop1][prop2][prop3] : prop2 ? e1[prop1][prop2] : e1[prop1], b = prop3 ? e2[prop1][prop2][prop3] : prop2 ? e2[prop1][prop2] : e2[prop1], sortOrder = direction === 'asc' ? 1 : -1;
    return (a < b) ? -sortOrder : (a > b) ? sortOrder : 0;
}

export function removeDuplicates(arr: Array<string>) {
    return arr.filter((item, 
        index) => arr.indexOf(item) === index);
}

export function findDistance(eventType: string) {
    switch (eventType) {
        case ("V10M"):
            return 10;
            break;
        case ("10M"):
            return 10;
            break;
        case ("V5K"):
            return 3.10686;
            break;
        case ("5K"):
            return 3.10686;
            break;
    }

    return 10;
}

export function expandNames(eventType: string) {
    switch (eventType) {
        case ("V10M"):
            return "Virtual 10 Mile Run";
        case ("10M"):
            return "10 Mile Run";
        case ("V5K"):
            return "Virtual 5K Run-Walk";
        case ("5K"):
            return "5K Run-Walk";
    }
}

export function eventTotals(events: any) {

    let totals = {
        tenMiTotalMiles: 0,
        tenMiTotalRaces: 0,
        tenMiAdjustedMiles: 0,
        tenMiAdjustedRaces: 0,
        fiveKTotalMiles: 0,
        fiveKTotalRaces: 0,
        fiveKAdjustedMiles: 0,
        fiveKAdjustedRaces: 0,
        totalMiles: 0,
        totalRaces: 0,
        adjustedMiles: 0,
        adjustedRaces: 0,
        hundredMileClubRemaining: 0,
    }

    for (const x in events) {
    
        let eventCounts: boolean = true;

        // Checking if an opposing event happened in the same year.
        // We allow Virtual Events to add to your total if you didn't also run the race in the same year
        // We are comparing events that begin with the letter V vs events that don't in the same year.
        if (Array.from(events[x]["event"]["eventType"])[0] == "V")
        {
            for (const y in events) {
                if (
                    events[y]["event"]["eventType"] == events[x]["event"]["eventType"].slice(1) && 
                    events[x]["event"]["year"] == events[y]["event"]["year"]
                ) {
                    eventCounts = false;
                }
            }
        }

        // Lets add to the mile totals.

        // console.log(events[x]);

        if (events[x]["event"]["eventType"] == "10M" || events[x]["event"]["eventType"] == "V10M") {
            totals.tenMiTotalMiles += Number(events[x]["event"]["advertisedDistance"]);
            totals.tenMiTotalRaces += 1;

            if (eventCounts) {
                totals.tenMiAdjustedMiles += Number(events[x]["event"]["advertisedDistance"]);
                totals.tenMiAdjustedRaces += 1;
            } 
        }

        if (events[x]["event"]["eventType"] == "5K" || events[x]["event"]["eventType"] == "V5K") {
            totals.fiveKTotalMiles += Number(events[x]["event"]["advertisedDistance"]);
            totals.fiveKTotalRaces += 1;

            if (eventCounts) {
                totals.fiveKAdjustedMiles += Number(events[x]["event"]["advertisedDistance"]);
                totals.fiveKAdjustedRaces += 1;
            }
        }

        

    }

    // Lets create the totals across the board
    totals.totalRaces = totals.fiveKTotalRaces + totals.tenMiTotalRaces;
    totals.totalMiles = totals.fiveKTotalMiles + totals.tenMiTotalMiles;

    totals.adjustedRaces = totals.fiveKAdjustedRaces + totals.tenMiAdjustedRaces;
    totals.adjustedMiles = totals.fiveKAdjustedMiles + totals.tenMiAdjustedMiles;

    totals.hundredMileClubRemaining = Math.max(0, 100 - totals.tenMiAdjustedMiles);

    return totals;

}

export function timeToSeconds(time: string) {
    const timeArray = time.split(":");
    let totalSeconds = 0;

    // we have hours
    if (timeArray.length == 3 ) {
        totalSeconds += Number(timeArray[0]) * 3600;
        totalSeconds += Number(timeArray[1]) * 60;
        totalSeconds += Number(timeArray[2]);
    } else if (timeArray.length == 2 ) {
        totalSeconds += Number(timeArray[0]) * 60;
        totalSeconds += Number(timeArray[1]);
    } else if (timeArray.length == 1 ) {
        totalSeconds += Number(timeArray[0]);
    }

    return totalSeconds;
}

export function secondsToTime (incomingSeconds: number) {
    var hours: number = 0;
    var minutes: number = 0;
    var seconds: number = 0;

    var remainder: number = incomingSeconds;

    // lets do hours
    if (remainder >= 3600) {

        hours = Math.floor(remainder / 3600);

        remainder = remainder - (hours * 3600);

        //return `${hours}`;

    }

    // lets do minutes
    if (remainder >= 60) {

        minutes = Math.floor(remainder / 60);

        remainder = remainder - (minutes * 60);

    }

    if (remainder >= 0) {
        seconds = Math.round(remainder);
    }

    if (hours) {
        return `${hours.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })}:${minutes.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })}:${seconds.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })}`;
    } else {
        return `${minutes.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })}:${seconds.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })}`;
    }
    

}

export function findPace(time: string, distance: number, returnSeconds: boolean = false) {
    // first we need to convert the total time into seconds so we can do some math.
    const seconds:number = timeToSeconds(time);

    if (returnSeconds)
        return seconds / distance;

    const paceSeconds: string = secondsToTime(seconds / distance);

    return paceSeconds;
}


export function identifyDivision(age: number, sex: string = "", division: string = "") {
    // this exists so we can basically just error check empty values. If a division is set then we can skip this routine
    if (division) {
        return division;
    }

    

    division = "";

    const ageGroups = [
        "01-19",
        "20-24",
        "25-29",
        "30-34",
        "35-39",
        "40-44",
        "45-49",
        "50-54",
        "55-59",
        "60-64",
        "65-69",
        "70-74",
        "75-79",
        "80-99",
    ]

    for (const x in ageGroups) {
        const ageRange = ageGroups[x].split("-");
        if (age >= Number(ageRange[0]) && age <= Number(ageRange[1])) {
            division = ageRange[0]+ageRange[1];
            break;
        }
    }

    division = `${sex}${division}`;

    return division;


}