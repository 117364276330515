    export function splitName(name: string): string[] {
        const prefixes = ["mr.", "mrs.", "ms.", "dr.", "rev.", "fr.", "sr.", "jr.", "prof.", "hon.", "pres.", "gov.", "sen.", "rep.", "atty.", "sgt.", "cpl.", "pvt.", "pfc.", "maj.", "col.", "lt.", "cpt.", "cmdr.", "adm.", "gen.", "amb.", "brig.", "capt.", "comdr.", "con.", "consul.", "counsel.", "dame.", "dipl.", "dr.", "econ.", "econ.", "ed.", "eng.", "esq.", "mr", "mrs", "ms", "dr", "rev", "fr", "sr", "jr", "prof", "hon", "pres", "gov", "sen", "rep", "atty", "sgt", "cpl", "pvt", "pfc", "maj", "col", "lt", "cpt", "cmdr", "adm", "gen", "amb", "brig", "capt", "comdr", "con", "consul", "counsel", "dame", "dipl", "dr", "econ", "econ", "ed", "eng", "esq", "excell"];
        const suffixes = ["jr.", "sr.", "ii", "iii", "iv", "v", "vi", "vii", "viii", "ix", "x", "phd", "md", "dds", "dvm", "esq.", "dmd", "dc", "dpm", "do", "rn", "rph", "pe", "cpa", "cfa", "cma", "cfp", "cmt", "cns", "cps", "csc"];
      
        const parts = name.split(' ');
        let firstName: string = '';
        let middleName: string = '';
        let lastName: string = '';
        let prefix: number = 0;
      
        if (parts.length === 1) {
          // Only one word, so it must be the last name
          lastName = parts[0];
        } else {
          // Iterate over the parts and assign them to the correct variable
          for (let i = 0; i < parts.length; i++) {
            if (i + prefix === 0 && prefixes.includes(parts[i].toLowerCase())) {
              // The first part is a prefix
              continue;
            } else if (i === parts.length - 1 && suffixes.includes(parts[i].toLowerCase())) {
              // The last part is a suffix
              continue;
            } else if (i + prefix === 0) {
              // The first part is the first name
              firstName = parts[i];
            } else if (i === parts.length - 1) {
              // The last part is the last name
              lastName = parts[i];
            } else {
              // All other parts are considered middle names
              middleName += parts[i] + ' ';
            }
          }
        }
      
        return [firstName, middleName.trim(), lastName];
    }