import React from 'react';
import { useParams } from 'react-router-dom';
import {RunnerListingView} from '../../fragments/runners/runnerlisting';

export function TopFStreakersFiveK() {
    let {page} = useParams();
    let pageNum = 1;

    if (!page) {
        pageNum = 1;
    } else {
        pageNum = Number(page);
    }
    return (<>
        <h1>Top Streakers 5K Run-Walk</h1>
        <RunnerListingView field="finishers" page={pageNum} orderBy="-longestStreakFiveK" link="streakers5k" />
        </>)
}