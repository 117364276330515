import React from 'react';
import {useQuery} from '@tanstack/react-query';
import { viewPerformanceById, viewRunnerBySlug } from '../utils/axios-query';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import { eventTotals, findPace } from '../utils/event-utils';
import { Button, Grid } from '@mui/material';
import { TisTid } from '../fragments/tid-tis';




export function PerformanceSingle() {

    let {id} = useParams();
    const navigate = useNavigate();
    const {isLoading, data, isError, isSuccess, error} = useQuery({
        queryKey: ['viewPerformance', id],
        queryFn: () => viewPerformanceById(id),
    });


    if (isLoading) {
        return ( <div className="loadingdata">Searching for Runner...</div> )
    }

    if (isError) {
        console.log(error);
        return (<div className="errordata">Unable to load runner at this time</div>)
    }
    
    if (isSuccess)
    {

        const performance = data.data.docs[0];

        const distance: number = performance.event.eventDistance;
        
        /* let distance: number = 10;
                                    
        if (["5K", "V5K"].includes(performance.event.eventType)) {
            distance = 3.10686;
        } */

        const runnerSlug = `/runner/${performance.runner.akaSlug}`
        
        
        return  (
            <>
            <Grid container spacing={1} justifyContent="center" alignItems="top">
                <Grid item xs={12} md={10} lg={8}>
                    <h1>{performance.runner.firstName} {performance.runner.middleInitial} {performance.runner.lastName}</h1>
                    <h2>Performance in the {performance.event.eventName} in {performance.event.year}</h2>

                    <Grid container spacing={1} justifyContent="center" alignItems="top">
                        <Grid item xs={12} lg={4}>
                            <h3>Registration:</h3>
                            <p><strong>Name:</strong> {performance.runner.firstName} {performance.runner.middleInitial} {performance.runner.lastName}</p>
                            <p><strong>Age:</strong> {performance.age}</p>
                            <p><strong>Hometown:</strong> {performance.homeTown}</p>
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <h3>Placed:</h3>
                            <p><strong>In Sex:</strong> {performance.pis} / {performance.tis}</p>
                            <p><strong>In Division:</strong> {performance.pid} / {performance.tid}</p>
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <h3>Time:</h3>
                            <p><strong>Time:</strong> {performance.netTime}</p>
                            <p><strong>Pace:</strong> {findPace(performance.netTime, distance)}</p>
                        </Grid>
                        
                    </Grid>
                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={6} lg={3}>
                            <Button variant="contained" fullWidth onClick={() => navigate(-1)}>
                                Go Back
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Button component={RouterLink} fullWidth variant="contained" to={runnerSlug}>
                                View Runner Details
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            </>
        )
        
    }

    return (<></>)
}
