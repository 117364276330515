import { Button, Grid } from '@mui/material';
import { Box, Container } from '@mui/system';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export function Nav() {

return <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={3} xl={2}>
            <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            >
                <Button component={RouterLink} variant="contained" to="/runner-name/">
                    Search By Runner Name
                </Button>
            </Box>
        </Grid>
        <Grid item xs={12} md={3} xl={2}>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            >
                <Button component={RouterLink} variant="contained" to="/performance-search/">
                Search By Age Group
                </Button>
            </Box>
        </Grid>
        <Grid item xs={12} md={3} xl={2}>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            >
                <Button component={RouterLink} variant="contained" to="/awards/">
                View All Time Lists
                </Button>
            </Box>
        </Grid>
    </Grid>;
}