
import { Control, Controller, FieldValues } from "react-hook-form";
import React, {FC} from "react";
import { TextField } from "@mui/material";


interface FormProps {
    name: string;
    control: Control<FieldValues>;
    label: string;
    style?: React.CSSProperties;
    fullWidth?: boolean,
    rules?: any,
    defaultValue?: string;
    type?: string;

  };

export const FormInputText: FC<FormProps> = ({name, control, type="text", label, style, fullWidth = false, rules = {}, defaultValue = ""}) => {
  return (
        <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <TextField onChange={onChange}  value={value} type={type} label={label} fullWidth={fullWidth} />
        )}
      />
    )
}