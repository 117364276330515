import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { Button, TextField, Grid, Container } from '@mui/material';
import { Runner } from '../types/runner';
import { useLocation } from 'react-router-dom';
import { fetchRunnerById } from "../utils/axios-query";



const fetchRunner = async (runnerId: string) => {
  const response = await fetch(`/api/runners/${runnerId}`);
  const data = await response.json();
  return data;
};

interface RunnerFormData extends Runner {
    [key: string]: any;
  }

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export const RunnerMergeForm: React.FC = () => {

    const runner1: Runner = {
        firstName: "John",
        middleInitial: "A",
        lastName: "Doe",
        previousName: "Johnny",
        dob: new Date("1990-01-01"),
        sex: "M",
        estBirthYear: 1990,
        akaSlug: "john-a-doe",
        slug: "john-doe",
        notes: "Runner 1",
        phone: "555-123-4567",
        email: "john.doe@example.com",
        city: "New York",
        state: "NY",
        country: "USA",
      };
      
      const runner2: Runner = {
        firstName: "Jane",
        middleInitial: "B",
        lastName: "Smith",
        previousName: "Janie",
        dob: new Date("1992-02-02"),
        sex: "F",
        estBirthYear: 1992,
        akaSlug: "jane-b-smith",
        slug: "jane-smith",
        notes: "Runner 2",
        phone: "555-987-6543",
        email: "jane.smith@example.com",
        city: "Los Angeles",
        state: "CA",
        country: "USA",
      };
      
  const queryParams = useQueryParams();
  const runnerId1 = queryParams.get('runnerOne') || '';
  const runnerId2 = queryParams.get('runnerTwo') || '';

  /* const { data: runner1 } = useQuery(['runner', runnerId1], () => fetchRunner(runnerId1));
  const { data: runner2 } = useQuery(['runner', runnerId2], () => fetchRunner(runnerId2)); */

  const [runnerThree, setRunnerThree] = useState<RunnerFormData>(runner2);

  const { register, handleSubmit } = useForm<RunnerFormData>({
    defaultValues: runnerThree,
  });

  const onSubmit = (data: RunnerFormData) => {
    console.log(data);
  };

  const handleMergeField = (field: string, value: any) => {
    setRunnerThree({ ...runnerThree, [field]: value });
  };

  const renderField = (field: keyof Runner, idx: number) => (
    <>
      <Grid item xs={4}>
        <TextField
          label={field}
          value={runner1[field]}
          InputProps={{ readOnly: true }}
          variant="outlined"
          fullWidth
        />
        <Button onClick={() => handleMergeField(field, runner1[field])}>Merge this field</Button>
      </Grid>
      <Grid item xs={4}>
        <TextField
          label={field}
          value={runner2[field]}
          InputProps={{ readOnly: true }}
          variant="outlined"
          fullWidth
        />
        <Button onClick={() => handleMergeField(field, runner2[field])}>Merge this field</Button>
      </Grid>
      <Grid item xs={4}>
        <TextField
          {...register(field)}
          label={field}
          value={runnerThree[field]}
          variant="outlined"
          fullWidth
        />
      </Grid>
    </>
  );

  if (!runner1 || !runner2) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          {Object.keys(runner1).map((field, idx) => renderField(field as keyof Runner, idx))}
        </Grid>
        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </form>
    </Container>
  );
};
