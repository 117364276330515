import { Button, Grid } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormInputCheckBoxGroup } from '../../forms/fields/text-checkbox-group-field';
import { FormInputDropDown } from '../../forms/fields/text-dropdown-field';
import { FormInputText } from '../../forms/fields/text-input-field';
import { getURLParam } from '../../utils/form-helpers';
import { RunnerAgeEventListing } from './age-events';

export function AgeForm(props: any) {
    const { register, handleSubmit, control, reset, setValue, watch, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const eventOptions = [
        {label: "10 Mile", value: "10M"},
        {label: "Virtual 10 Mile", value: "V10M"},
        {label: "5 Kilometer", value: "5K"},
        {label: "Virtual 5 Kilometer", value: "V5K"},
    ];

    const divisionOptions = [
        {label: "Overall Women", value: "W"},
        {label: "Overall Men", value: "M"},
        {label: "W0119", value: "W0119"},
        {label: "W2024", value: "W2024"},
        {label: "W2529", value: "W2529"},
        {label: "W3034", value: "W3034"},
        {label: "W3539", value: "W3539"},
        {label: "W4044", value: "W4044"},
        {label: "W4549", value: "W4549"},
        {label: "W5054", value: "W5054"},
        {label: "W5559", value: "W5559"},
        {label: "W6064", value: "W6064"},
        {label: "W6569", value: "W6569"},
        {label: "W7074", value: "W7074"},
        {label: "W7579", value: "W7579"},
        {label: "W8099", value: "W8099"},

        {label: "M0119", value: "M0119"},
        {label: "M2024", value: "M2024"},
        {label: "M2529", value: "M2529"},
        {label: "M3034", value: "M3034"},
        {label: "M3539", value: "M3539"},
        {label: "M4044", value: "M4044"},
        {label: "M4549", value: "M4549"},
        {label: "M5054", value: "M5054"},
        {label: "M5559", value: "M5559"},
        {label: "M6064", value: "M6064"},
        {label: "M6569", value: "M6569"},
        {label: "M7074", value: "M7074"},
        {label: "M7579", value: "M7579"},
        {label: "M8099", value: "M8099"},
    ]

    let startYear: number = 1973;
    let currentYear: number = new Date().getFullYear();
    let yearObjects: {label: string, value: string}[] = [];
    
    for(let i: number = currentYear; i >= startYear; i--){
        let yearString: string = `${i}`;
        yearObjects.push({label: yearString, value: yearString});
    }


    const onSubmit = async (data: any) => {
        setSearchParams({
            "eventType": data.eventType, 
            "year": data.year,
            "division": data.division,
            "page": "1"
        });
        
    }
    let defaultDivision = 'W';
    if (searchParams.get("division")) {
        defaultDivision = searchParams.get("division")!;
    }

    let defaultYear = `${new Date().getFullYear()}`;
    if (searchParams.get("year")) {
        defaultYear = searchParams.get("year")!;
    }


    return <>
    <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1} justifyContent="space-evenly" alignItems="center">
            <Grid item lg={4} xs={12}>
                Event <br />
                <FormInputCheckBoxGroup name="eventType" control={control} defaultValue={getURLParam("eventType", null)} setValue={setValue} label="" options={eventOptions} />
            </Grid>
            <Grid item lg={4} xs={12}>
                Year <br />
                {/* <FormInputText name="year" fullWidth={true} defaultValue={defaultYear} rules={{pattern: /^[0-9]{4}$/}} type="number" control={control} label=""  /> */}
                <FormInputDropDown name="year" fullWidth={true} defaultValue={defaultYear} control={control} label="" options={yearObjects} />
            </Grid>
            <Grid item lg={4} xs={12}>
                Division <br />
                <FormInputDropDown name="division" fullWidth={true} defaultValue={defaultDivision} control={control} label="" options={divisionOptions} />
            </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="center" alignItems="center">
            <Grid item md={4} xs={12}>
                <Button type="submit" onClick={handleSubmit(onSubmit)} fullWidth variant="contained">Submit</Button>
            </Grid>
        </Grid>
    </form>
    {
        searchParams.get("eventType") != null && 
        <RunnerAgeEventListing 
            eventType={getURLParam("eventType", null)} 
            division={searchParams.get("division")} 
            year={searchParams.get("year")}
            page={searchParams.get("page")} 
        />
    }
    </>;
}